import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PresntationGrid from "../components/PresntationGrid"
import BgHolder from "../components/BgHolder"
import bg from "../images/bg.jpg"
import * as style from "../landing.module.scss"

const IndexPage = () => (
  <Layout>
    <BgHolder style={{ backgroundImage: `url("${bg}")` }} />
    <Seo title="Home" />
    <h1 className={style.blink}>Dream Travel</h1>
    <PresntationGrid />
  </Layout>
)

export default IndexPage
