import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as style from "./presntationGrid.module.scss"

const destinations = [
  {
    name: "Cyprus",
    image: (
      <StaticImage
        width={500}
        height={500}
        src="../images/cyprus.jpg"
        alt="Cyprus"
      />
    ),
    link: "/destination/cyprus",
  },
  {
    name: "Tunisia",
    image: (
      <StaticImage
        width={500}
        height={500}
        src="../images/tunisia.jpg"
        alt="Tunisia"
      />
    ),
    link: "/destination/tunisia",
  },
  {
    name: "Saint Lucia",
    image: (
      <StaticImage
        width={500}
        height={500}
        src="../images/st-luicia.jpg"
        alt="Saint Lucia"
      />
    ),
    link: "/destination/st-luicia",
  },
  {
    name: "Croatia",
    image: (
      <StaticImage
        width={500}
        height={500}
        src="../images/croatia.jpg"
        alt="Croatia"
      />
    ),
    link: "/destination/croatia",
  },
]
const DestinationThumbNail = ({ name, image, link }) => {
  return (
    <div className={style.item}>
      <Link to={link}>
        <h2>{name}</h2>
        {image}
      </Link>
    </div>
  )
}

export default function PresntationGrid() {
  return (
    <div className={style.presentationGrid}>
      {destinations.map(destination => (
        <DestinationThumbNail key={destination.link} {...destination} />
      ))}
    </div>
  )
}
